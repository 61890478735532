import React from "react";
import {
  FaEnvelopeOpenText,
  FaMapMarkerAlt,
  // FaMobileAlt,
  // FaRegCalendarAlt,
} from "react-icons/fa";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import aboutImage from "../../assets/images/about/about.jpg";
import "../../Shared/Style.css";
import Footer from "../../Shared/Footer";
import UseData from "../../Hooks/UseData";
// import SliderCommon from "../../Shared/SliderCommon";
import AboutCard from "./AboutCard";
import PageTitle from "../../Shared/PageTitle";

const About = () => {
  const { local, experienceArray } = UseData();
  return (
    <>
      {/* Start page title */}
      <PageTitle title="About"></PageTitle>
      {/* End page title */}

      <section className="w-full lg:w-11/12 mx-auto">
        <div className="container lg:rounded-t-2xl bg-white dark:bg-[#111111] px-4 sm:px-5 md:px-10 lg:px-20">
          <div data-aos="fade">
            <div className="py-12">
              {/* Page Title */}
              <h2 className="after-effect after:left-52 mt-12 lg:mt-0 ">
                About Me
              </h2>
              <div className="grid grid-cols-12 md:gap-10 pt-4 md:pt-[40px] items-center">
                <div className="col-span-12 md:col-span-5 xl:col-span-4">
                  {/* personal images for about page  */}
                  <img
                    className="w-full md:w-[330px] md:h-[400px] object-cover overflow-hidden rounded-[35px] mb-3 md:mb-0"
                    src={aboutImage}
                    alt=""
                  />
                </div>
                <div className="col-span-12 md:col-span-7 xl:col-span-8 space-y-2.5">
                  {/* About me information */}
                  <div className=" md:mr-12 xl:mr-16">
                    <h3 className="text-4xl font-medium dark:text-white mb-2.5 ">
                      Who am i?
                    </h3>
                    <p className="text-gray-lite  dark:text-color-910 leading-7">
                      I am a goal-oriented and mission-driven full-stack
                      software developer from India with 8 years+ of industry
                      experience, and with an ability to self-manage during
                      independent projects, as well as collaborate as part of a
                      productive team.
                    </p>
                    <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                      Currently I am looking for opportunities to work in AI/ML
                      projects.
                    </p>
                  </div>

                  {/* personal information */}
                  <div>
                    <h3 className="text-4xl font-medium my-5 dark:text-white">
                      Personal Info
                    </h3>
                    <div className=" grid grid-cols-1 sm:grid-cols-2 gap-6">
                      <div className="flex">
                        <span className="text-green dark:bg-color-990 shadow-icon mr-2.5 flex items-center justify-center rounded-md text-2xl w-12 text-">
                          <FaEnvelopeOpenText />
                        </span>
                        <div className="space-y-1">
                          <p className="text-xs text-gray-lite dark:text-color-910">
                            Email
                          </p>
                          <h6 className="font-medium dark:text-white">
                            anindya.dey01@gmail.com
                          </h6>
                        </div>
                      </div>
                      <div className="flex">
                        <span className="text-oriange-lite dark:bg-color-990 shadow-icon mr-2.5 flex items-center justify-center rounded-md text-2xl w-12 text-">
                          <FaMapMarkerAlt />
                        </span>
                        <div className="space-y-1">
                          <p className="text-xs text-gray-lite dark:text-color-910">
                            Location
                          </p>
                          <h6 className="font-medium dark:text-white">
                            Nagaland, India
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End personal information */}
                </div>
              </div>
            </div>

            <div className="  pb-12 ">
              <h3 className="text-[35px] dark:text-white font-medium pb-5">
                What do I do?
              </h3>
              <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2">
                {/* Experience information  */}

                {experienceArray.map((item, i) => (
                  <AboutCard key={i} item={item} local={local} />
                ))}
              </div>
            </div>

            <div>
              {/* Slick Slider call here  */}
              {/* <SliderCommon /> */}
            </div>

            {/* Common Footer call here */}
          </div>
        </div>
        <Footer condition={true} bg={"#F8FBFB"} />
      </section>
    </>
  );
};

export default About;
